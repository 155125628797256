import React, { useState } from "react"
import { useForm, ErrorMessage } from "react-hook-form"
import { graphql, useStaticQuery } from "gatsby"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExclamationTriangle } from "../../../fontAwesome/pro-duotone-svg-icons"
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const AccessibilityReportingForm = () => {
  const { register, handleSubmit, formState, errors, reset } = useForm({
    mode: "onBlur",
  })

  const data = useStaticQuery(graphql`
    {
      sanityAccessibilityReportingPage {
        accessibilityReportingForm {
          buttonText
          email
          emailPattern
          emailPlaceholder
          emailRequired
          firstName
          firstNamePlaceholder
          firstNameRequired
          lastName
          lastNamePlaceholder
          lastNameRequired
          message
          messagePlaceholder
          successMessage
        }
      }
    }
  `)
  const formData =
    data.sanityAccessibilityReportingPage.accessibilityReportingForm

  const [state, setState] = React.useState({})
  const [feedbackState, setFeedbackState] = useState(null)
  const [errorState, setErrorState] = useState(null)

  const errorsFirstName = classNames("inputStyle", {
    inputStyleValid: !errors.firstName,
    inputStyleError: errors.firstName,
  })
  const errorsLastName = classNames("inputStyle", {
    inputStyleValid: !errors.lastName,
    inputStyleError: errors.lastName,
  })
  const errorsEmail = classNames("inputStyle", {
    inputStyleValid: !errors.email,
    inputStyleError: errors.email,
  })
  const errorsPhoneNumbers = classNames("inputStyle", {
    inputStyleValid: !errors.phoneNumber,
    inputStyleError: errors.phoneNumber,
  })

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const onSubmit = (data, e) => {
    e.preventDefault()
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "accessibilityIssueForm",
        ...state,
      }),
    })
      .then(response => {
        setFeedbackState(true)
        reset()
      })
      .catch(error => {
        setErrorState(false)
      })
  }
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      name="accessibilityIssueForm"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      autocomplete="on"
    >
      <div className="field " className={errorsFirstName}>
        <label for="field-fName" className="labelStyle">
          {formData.firstName}
        </label>
        <div id="error-required-field-fName">
          <ErrorMessage
            errors={errors}
            name="firstName"
            message={formData.firstNameRequired}
          >
            {({ message }) => (
              <span className="errorMessage">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="formErrorIcon"
                  role="pressentation"
                />
                {message}
              </span>
            )}
          </ErrorMessage>
        </div>
        <div className="control">
          <input
            className={errorsFirstName}
            id="field-fName"
            type="text"
            name="firstName"
            placeholder={formData.firstNamePlaceholder}
            onChange={handleChange}
            ref={register({ required: true, maxLength: 75 })}
            aria-describedby="error-required-field-fName"
            autocomplete="given-name"
            required
          />
        </div>
      </div>

      <div className="field" className={errorsLastName}>
        <label for="field-lName" className="labelStyle">
          {formData.lastName}
        </label>
        <div id="error-required-field-lName">
          <ErrorMessage
            errors={errors}
            name="lastName"
            message={formData.lastNameRequired}
          >
            {({ message }) => (
              <span className="errorMessage">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="formErrorIcon"
                  role="pressentation"
                />
                {message}
              </span>
            )}
          </ErrorMessage>
        </div>
        <div className="control">
          <input
            className={errorsLastName}
            type="text"
            id="field-lName"
            placeholder={formData.lastNamePlaceholder}
            name="lastName"
            autocomplete="family-name"
            required
            aria-describedby="error-required-field-lName"
            onChange={handleChange}
            ref={register({ required: true, maxLength: 8 })}
          />
        </div>
      </div>

      <div className="field">
        <label for="field-business" className="labelStyle">
          {formData.companyName}
        </label>

        <div className="field" className={errorsEmail}>
          <label for="email" className="labelStyle">
            {formData.email}
          </label>
          <span
            id="error-required-field-email"
            className="errorMessage"
            style={{
              display:
                errors.email && errors.email.type === "required"
                  ? "block"
                  : "none",
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="formErrorIcon"
              role="pressentation"
            />
            {formData.emailRequired}
          </span>
          <span
            id="error-required-field-pattern"
            className="errorMessage"
            style={{
              display:
                errors.email && errors.email.type === "pattern"
                  ? "block"
                  : "none",
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="formErrorIcon"
              role="pressentation"
            />
            {formData.emailPattern}
          </span>
          <span
            id="error-required-field-maxLength"
            className="errorMessage"
            style={{
              display:
                errors.email && errors.email.type === "maxLength"
                  ? "block"
                  : "none",
            }}
          >
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="formErrorIcon"
              role="pressentation"
            />
            Max length of 100 characters exceeded
          </span>

          <input
            type="email"
            id="email"
            name="email"
            className={errorsEmail}
            aria-invalid={errors.email ? "true" : "false"}
            placeholder={formData.emailPlaceholder}
            aria-describedby="error-required-field-email error-required-field-maxLength error-required-field-pattern"
            onChange={handleChange}
            autocomplete="email"
            ref={register({
              required: true,
              pattern: /^[a-zA-Z0-9\-.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/,
              maxLength: 100,
            })}
          />
        </div>
      </div>

      <div className="field">
        <label for="message" className="labelStyle">
          {formData.message}
        </label>

        <div className="control">
          <textarea
            id="message"
            className="inputStyle"
            type="text"
            placeholder={formData.messagePlaceholder}
            name="message"
            onChange={handleChange}
            ref={register()}
            rows="7"
          />
        </div>
      </div>
      <div className="field is-grouped">
        <div className="control">
          {feedbackState && (
            <div className="notification is-link">
              {formData.successMessage}
            </div>
          )}
          {errorState && (
            <div className="notification is-warning">
              Something went wrong on our, sorry about that! Please contact us
              manually
            </div>
          )}

          <button className="button is-link" type="submit">
            Submit form
          </button>
        </div>
      </div>
    </form>
  )
}
export default AccessibilityReportingForm
